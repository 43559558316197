import { HomeOutlined, Home } from "@mui/icons-material";
import AddTaskIcon from "@mui/icons-material/AddTask";
import AddTaskIconOutlined from "@mui/icons-material/AddTaskOutlined";
import PollIcon from "@mui/icons-material/Poll";
import PollOutlinedIcon from "@mui/icons-material/PollOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import DescriptionIcon from "@mui/icons-material/Description";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import Sidebar from "../atoms/sidebar/Sidebar";
import SidebarItem from "../atoms/sidebar/SidebarItem";
import SettingsAccessibilityIcon from "@mui/icons-material/SettingsAccessibility";
import SettingsAccessibilityOutlinedIcon from "@mui/icons-material/SettingsAccessibilityOutlined";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useLocation } from "react-router-dom";
import CreateIcon from "@mui/icons-material/Create";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import classes from "./AdminSidebar.module.css";
import EmailIcon from "@mui/icons-material/Email";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

export default function AdminSidebar() {
  const location = useLocation();
  const path = location.pathname;
  return (
    <>
      <Sidebar>
        <div className={classes.borderLine}>
          <SidebarItem
            to=""
            isActive={path === "/admin"}
            activeIcon={<Home color="primary" />}
            pendingIcon={<HomeOutlined />}
            text="Home"
          />
          {/* <SidebarItem
            to="activity-status"
            pendingIcon={<PollOutlinedIcon />}
            activeIcon={<PollIcon color="primary" />}
            text="Activity Status"
            isActive={path === "/activity-status"}
            disabled="true"
          /> */}
          <SidebarItem
            to="manage-initiatives"
            pendingIcon={<CreateOutlinedIcon />}
            activeIcon={<CreateIcon color="primary" />}
            text="Manage Initiatives"
            isActive={path === "/admin/manage-initiatives"}
          />
          <SidebarItem
            to="enrollment-questionnaires"
            pendingIcon={<CreateOutlinedIcon />}
            activeIcon={<CreateIcon color="primary" />}
            text="Enrollment Questionnaires"
            isActive={path === "/admin/enrollment-questionnaires"}
          />
          <SidebarItem
            to="enrollment"
            pendingIcon={<AddTaskIconOutlined />}
            activeIcon={<AddTaskIcon color="primary" />}
            text="Enrollment"
            isActive={
              path === "/admin/enrollment" || path === "/admin/enroll-site"
            }
          />
          <SidebarItem
            to="sites-champions"
            pendingIcon={<SettingsAccessibilityOutlinedIcon />}
            activeIcon={<SettingsAccessibilityIcon color="primary" />}
            text="Sites & Champions"
            isActive={path === "/admin/sites-champions"}
          />
          <SidebarItem
            to="reports"
            pendingIcon={<DescriptionOutlinedIcon />}
            activeIcon={<DescriptionIcon color="primary" />}
            text="Reports"
            isActive={path === "/admin/reports"}
          />
          <SidebarItem
            to="performance-validation"
            pendingIcon={<UploadFileOutlinedIcon />}
            activeIcon={<UploadFileIcon color="primary" />}
            text="Performance Validation"
            isActive={path === "/admin/performance-validation"}
          />
          <SidebarItem
            to="manage-users"
            pendingIcon={<PeopleAltOutlinedIcon />}
            activeIcon={<PeopleAltIcon color="primary" />}
            text="Manage Users"
            isActive={path === "/admin/manage-users"}
          />
          {/* <SidebarItem
            to="communication"
            pendingIcon={<EmailOutlinedIcon />}
            activeIcon={<EmailIcon color="primary" />}
            text="Communication"
            isActive={path === "/admin/communication"}
          /> */}
        </div>
      </Sidebar>
    </>
  );
}

import { createBrowserRouter } from "react-router-dom";
import AdminHomePage from "../pages/admin/AdminHomePage";
import AdminLayout from "../pages/admin/AdminLayout";
import ActivityBuilderPage from "../pages/admin/initiative/activity/ActivityBuilder";
import ActivityListPage from "../pages/admin/initiative/activity/ActivityList";
import ActivityPublishPage from "../pages/admin/initiative/activity/ActivityPublish";
import CreateInitiativePage from "../pages/admin/initiative/CreateInitiative";
import EditInitiativePage from "../pages/admin/initiative/EditInitiative";
import InitiativeLayout from "../pages/admin/initiative/InitiativeLayout";
import ManageInitiativesPage from "../pages/admin/initiative/ManageInitiatives";
import HomePage from "../pages/Home";
import RootLayout from "../pages/RootLayout";
import SiteChampionsHomePage from "../pages/admin/site-champions/SiteChampionsDashboard";

// Champion pages

import EnrollmentSitePage from "../pages/admin/enrollment/EnrollmentSitePage";
import EnrollmentPageAdmin from "../pages/admin/enrollment/EnrollmentDashboard";

// Enrollment Questionnaire pages
import ManageEnrollmentQuestionnaireHome from "../components/admin/ManageEnrollmentQuestionnaireHome";
import ManageEnrollmentQuestionnaireLayout from "../components/admin/enrollment/manage-enrollment/ManageEnrollmentQuestionnaireLayout";
import { CreateEnrollmentQuestionnairePage } from "../pages/admin/enrollment/CreateEnrollmentQuestionnairePage";
import { UpdateEnrollmentQuestionnairePage } from "../pages/admin/enrollment/UpdateEnrollmentQuestionnairePage";
import { EnrollmentQuestionnaireBuilderPage } from "../pages/admin/enrollment/EnrollmentQuestionnaireBuilderPage";
import { EnrollmentQuestionnairePublishPage } from "../pages/admin/enrollment/EnrollmentQuestionnairePublishPage";
import ReportsHomePage from "../pages/admin/reports/ReportsDashboard";
import PerformanceValidationDashboard from "../pages/admin/performance-validation/PerformanceValidationDashboard";
import ManageUsersHomePage from "../pages/admin/manage-users/ManageUsersHomePage";
import RouteChangeListener from "./RouteChangeListener";
import CommunicationHomePage from "../pages/admin/communication/CommunicationHomePage";

export const adminRoutes = createBrowserRouter([
  {
    path: "/",
    element: (
      <RouteChangeListener>
        <RootLayout />
      </RouteChangeListener>
    ),
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: "admin",
        element: <AdminLayout />,
        children: [
          {
            index: true,
            path: "",
            element: <AdminHomePage />,
          },
          { path: "sites-champions", element: <SiteChampionsHomePage /> },
          { path: "reports", element: <ReportsHomePage /> },
          { path: "enroll-site", element: <EnrollmentSitePage /> },
          {
            path: "enrollment",
            element: <EnrollmentPageAdmin />,
            children: [
              {
                index: true,
                element: <EnrollmentPageAdmin />,
              },
              {
                path: "enroll-site",
                element: <EnrollmentSitePage />,
              },
            ],
          },
          {
            path: "manage-initiatives",
            element: <ManageInitiativesPage />,
          },
          {
            path: "initiative",
            element: <InitiativeLayout />,
            children: [
              {
                path: "create",
                element: <CreateInitiativePage />,
              },
              {
                path: "update",
                element: <EditInitiativePage />,
              },
              {
                path: "activity",
                children: [
                  {
                    path: "list",
                    element: <ActivityListPage />,
                  },
                  {
                    path: "builder",
                    element: <ActivityBuilderPage />,
                  },
                  {
                    path: "publish",
                    element: <ActivityPublishPage />,
                  },
                ],
              },
            ],
          },
          { path: "manage-users", element: <ManageUsersHomePage /> },
          // { path: "communication", element: <CommunicationHomePage /> },
          {
            path: "enrollment-questionnaires",
            element: <ManageEnrollmentQuestionnaireHome />,
          },
          {
            path: "performance-validation",
            element: <PerformanceValidationDashboard />,
          },
          {
            path: "questionnaire",
            element: <ManageEnrollmentQuestionnaireLayout />,
            children: [
              {
                path: "create",
                element: <CreateEnrollmentQuestionnairePage />,
              },
              {
                path: "update",
                element: <UpdateEnrollmentQuestionnairePage />,
              },
              {
                path: "builder",
                element: <EnrollmentQuestionnaireBuilderPage />,
              },
              {
                path: "publish",
                element: <EnrollmentQuestionnairePublishPage />,
              },
            ],
          },
        ],
      },
    ],
  },
]);

import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import WaitIndicator, { WaitIndicatorRef } from "../../atoms/WaitIndicator";
import { onQuestionClearFiles } from "../../common/QnASheet";
import { useRef } from "react";
import { getGuid } from "../../../helpers/ui-util";
import { uploadFilesToAzureStorageContainer } from "../../../helpers/api-util";

export interface QuestionaireViewerComponentProps {
  activity: any;
}

function QuestionaireViewerComponent(props: QuestionaireViewerComponentProps) {
  let surveyJson = {
    elements: [],
    widthMode: "responsive",
  };
  const { activity } = props;
  const waitIndicatorLoaderRef = useRef<WaitIndicatorRef>(null);
  const guid = getGuid();
  if (!activity) {
    return <p>Activity information not found! </p>;
  }
  if (activity && activity.definition) {
    surveyJson = JSON.parse(activity.definition);
  } else {
    surveyJson = {
      elements: [],
      widthMode: "responsive",
    };
  }

  const survey = new Model(surveyJson);

  survey.onUploadFiles.add(async (sender: any, options: any) => {
    try {
      var acceptedFileTypeIndex = options.question.acceptedTypes.indexOf(
        options.files[0].type
      );
      //below code is to check for file extention for heic,mobi,mov,mus,musx,sib,key
      if (acceptedFileTypeIndex === 0 && options.files[0].type.trim() === "") {
        var fileExtension = options.files[0].name.split(".").pop();
        var acceptedFileExtension = "heic,mobi,mov,mus,musx,sib,key,wpd";
        if (acceptedFileExtension.indexOf(fileExtension) < 0) {
          options.question.addError("Invalid file type.");
          options.callback("error");
          return;
        }
      }
      if (acceptedFileTypeIndex < 0) {
        options.question.addError("Invalid file type.");
        waitIndicatorLoaderRef.current?.hide();
        options.callback("error");
        return;
      }
      const fileData = await uploadFilesToAzureStorageContainer(
        options.files,
        "testingquestionnaire",
        `files/${guid}`
      );
      if (fileData && fileData.data) {
        options.callback(
          "success",
          options.files.map((file: any) => {
            return {
              file: file,
              content: fileData.data.files[0].fileUri,
            };
          })
        );
      }
    } catch (error) {
      console.error("Error:", error);
    }
  });

  survey.onClearFiles.add(async (sender: any, options: any) => {
    waitIndicatorLoaderRef.current?.show();
    await onQuestionClearFiles(sender, options);
    waitIndicatorLoaderRef.current?.hide();
  });

  return (
    <div>
      <WaitIndicator ref={waitIndicatorLoaderRef} />
      <Survey model={survey} showNavigationButtons={false} />
    </div>
  );
}

export default QuestionaireViewerComponent;
